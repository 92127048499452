<template>
	<div>
		<!-- <el-dialog :title="$t('i18nn_0f6916ca53218662')" append-to-body :close-on-click-modal="false" :visible.sync="dialogFile"
			width="1200px" top="10px"> -->
		<el-drawer :wrapperClosable="false" :title="$t('i18nn_0f6916ca53218662')" append-to-body :visible.sync="dialogFile" :direction="'rtl'" size="1200px">
			<div class="">
				<!--本页切换列表-->
				<div>
					<div class="tableCon" v-loading="loading_load" element-loading-text="加载中...">
						<div class="tableConTable">
							<!-- <HyQuillEditor ref="myTextEditor" :value="leaveMessage" @editorChange="onEditorChange" class=""></HyQuillEditor> -->

							<el-form ref="form" :size="''" :rules="formRules" :model="form" label-width="100px"
								style="width: 1000px;" inline v-loading="loading"
								element-loading-text="加载中...">
								<el-form-item :label="$t('i18nn_b95047968d1358d4')" prop="" required>
									<el-radio-group v-model="form.status">
										<div>
											<el-radio :label="item.code" v-for="(item,index) in selectOption.statusList"
												:key="index">
												<span v-if="'en'==$i18n.locale">{{item.codeTextEn}}</span>
												<span v-else>{{ $Utils.i18nCodeText(item) }}</span>
											</el-radio>
										</div>
									</el-radio-group>
								</el-form-item>
								<el-form-item :label="$t('i18nn_466677324278a9a1')" required>
									<el-input type="textarea" :rows="3" :placeholder="$t('i18nn_5a9aefbc03c778f7')" v-model="form.qaText"
										:maxlength="2000" show-word-limit style="width: 800px;"></el-input>
								</el-form-item>

								<el-form-item :label="$t('i18nn_805c8f776e461e39')" prop="">
									<HyQuillEditor ref="myTextEditor" :value="form.qaMoreText"
										@editorChange="onEditorChange" class=""></HyQuillEditor>
								</el-form-item>

								<el-form-item :label="$t('i18nn_f5d43732e3f6cf4d')" prop="" v-loading="loadingUploadFile">
									<el-button type="primary" icon="el-icon-paperclip" @click="openAddFile()">{{$t('i18nn_ad36057ff6a7ce6b')}}</el-button>

									<ul>
										<li v-for="(item,index) in form.attachments" :key="index">
											<el-image style="width: 50px; height: 50px;vertical-align: middle;"
												:z-index="9999" :fit="'contain'" :src="item.url"
												:preview-src-list="form.attachments.map(itemPre=> { return itemPre.url})">
												<div slot="error" class="image-slot">
												  <i class="el-icon-document"></i>
												</div>
											</el-image>
											<a :href="item.url" :title="item.url"
												target="_blank">{{ item.fileName }}</a>

											<!-- <el-button @click="delFile($event, item)" type="text" size="mini" icon="el-icon-delete"
											style="padding: 0 10px;">{{$t('i18nn_e33c9b93c36fd250')}}</el-button> -->
											
											<el-button @click="delFileAction($event, item,index)" type="text" size="mini"
												icon="el-icon-delete" style="padding: 0 10px;" v-if="item.id">{{$t('i18nn_e33c9b93c36fd250')}}</el-button>
											<el-button @click="delFile($event, index)" type="text" size="mini" icon="el-icon-delete"
												style="padding: 0 10px;" v-else>{{$t('i18nn_e33c9b93c36fd250')}}</el-button>
												
										</li>
									</ul>
								</el-form-item>

							</el-form>

						</div>
					</div>
				</div>
			</div>
			<div class="drawer-footer">
				<el-button type="primary" @click="submitAction()">{{ $t('i18nn_ad4345dbaabe1479') }}</el-button>
				<!-- <el-button type="primary" plain @click="dialogFile = false">{{ $t('i18nn_4e9fc68608c60999') }}</el-button> -->
			</div>
		</el-drawer>
		<!-- </el-dialog> -->

		<!-- 附件 -->
		<dialogFileUpload ref="dialogFileUpload" :folder="'QARecProgress/'"
			:openTime="FileUploadOpenTime" @success="FileUploadSuccess"></dialogFileUpload>
			
		
	</div>
</template>
<script>
	// import LgsFedexTrackTem from '@/components/StorageCenter/ExpressSheet/LgsFedexTrackTem.vue';
	// import LgsUpsTrackSimTem from '@/components/StorageCenter/ExpressSheet/LgsUpsTrackSimTem.vue';
	import dialogFileUpload from '@/components/StorageCenter/components/dialogFileUpload.vue';
	
	import HyQuillEditor from '@/components/Common/HyQuillEditor.vue';

	export default {
		// name: 'HomeConfFrame',
		props: {
			openTime: {
				// default: function() {
				//   return '';
				// },
				// type: String
			},
			row: {}
		},
		components: {
			// LgsFedexTrackTem,
			// LgsUpsTrackSimTem
			dialogFileUpload,
			HyQuillEditor
		},
		data() {
			return {
				dialogFile: false,
				loading_load: false,

				//表格数据
				//loading,表格数据
				loading: false,
				// leaveMessage:"",

				//表格数据
				// fedexData: {},
				// upsData:{},
				// dataOpenTime:"",
				//分页数据
				// pagination: {
				// 	current_page: 1,
				// 	page_sizes: [5, 20, 50, 100, 200, 300],
				// 	page_size: 200,
				// 	total: 0
				// },
				FileUploadOpenTime: '',
				// FileUploadOpenIndex: '',
				

				selectOption: {
					statusList: [{
						code: "10",
						codeText: this.$t('i18nn_cce168301447d1ba'),
						codeTextEn:"underway"
					}, {
						code: "30",
						codeText: this.$t('i18nn_1fb4422ebabb847a'),
						codeTextEn:"normal termination"
					}, {
						code: "40",
						codeText: this.$t('i18nn_0eb9b2e0d01ad12b'),
						codeTextEn:"abnormal termination"
					}]
				},
				
				loadingUploadFile:false,
				
				form: {
					"status": "10", //"状态(10: 提交  30： 正常结束  40: 异常结束)",
					"qaMoreText": null, //this.$t('i18nn_450e8e72d3a52ce2'),
					"qaText": null, //this.$t('i18nn_3d23fd1011cdc075'),
					"attachments": [
						// {
						// 		"fileName":"",
						// 		"url":"",
						// 		"type":""
						// }
					]
				},
				formRules: {},
				// selectOption: {
				// 	wh_no: [],
				// },
				//查询，排序方式
				filterData: {
					// orderBy: 'id_', //排序字段
					// sortAsc: 'desc', //desc降序，asc升序

					// orderNumber: '',
					"id": null, //"数据ID",
					// "carrier": null, //this.$t('i18nn_2279b6465da730e9'),
					// "tracking": null, //this.$t('i18nn_4531476fa35570f0')
				}
			};
		},
		watch: {
			openTime: function(newVal, oldVal) {
				console.log('openTime');
				this.initData();
				//初始化
				this.dialogFile = true;
			}
		},

		activated() {
			console.log(' activated');
		},
		//创建时
		created() {
			console.log(' created');
		},
		//编译挂载前
		mounted() {
			// this.getDicData();
			// this.getWhNoListData();
		},
		methods: {
			// goBack() {

			// },

			initData() {
				this.filterData.id = this.row.id;

				this.form = {
					"status": "10",
					"qaMoreText": null, //this.$t('i18nn_74f0f436fa58dfe4'),
					"qaText": null, //this.$t('i18nn_3d23fd1011cdc075')
					"attachments": []
				};

				// this.getDetData();
			},

			// 富文本
			onEditorChange(html) {
				// this.form.contentStr = html;
				this.form.qaMoreText = html;
			},

			//添加附件
			openAddFile() {
				// this.FileUploadOpenIndex = index;
				this.FileUploadOpenTime = new Date().getTime();
			},

			//附件上传成功回调
			FileUploadSuccess(data) {
				console.log('FileUploadSuccess', data);
				let fileList = data.map(item => {
					return {
						fileName: item.fileName, //this.$t('i18nn_89221ad15d2ec113'),
						url: item.filePath, //this.$t('i18nn_8758fd50c87d6c9c')
						type: "90"
					}
				});
				if(!this.form.attachments){
					this.form.attachments = [];
				}
				// this.form.attachments = fileList;
				this.form.attachments = this.form.attachments.concat(fileList);
				this.$forceUpdate();
			},
			//删除附件
			delFile(event, index) {
				event.stopPropagation();
				this.form.attachments.splice(index, 1);
				this.$forceUpdate();
			},
			//后端删除
			delFileAction(event,row,index) {
				event.stopPropagation();
			  this.loadingUploadFile = true;
			  this.$http
			    .delete(this.$urlConfig.WhFileUploadDel + '/' + row.id, {})
			    .then(({ data }) => {
			      this.loadingUploadFile = false;
			      if (200 == data.code) {
							this.form.attachments.splice(index, 1);
							this.$forceUpdate();
							this.$message.success(this.$t('i18nn_de017dafc266aa03'));
			      } else {
							this.$message.warning(data.msg ? data.msg : this.$t('i18nn_54dc58d9d0377bb5'));
			      }
			    })
			    .catch(error => {
			      console.log(error);
						this.loadingUploadFile = false;
						this.$message.warning(data.msg ? data.msg : '删除失败!');
			    });
			},
			// //请求数据
			// getDetData() {

			// 	this.loading_load = true;
			// 	this.$http
			// 		.get(this.$urlConfig.WhSendGoodsCommentDet + "/" + this.filterData.id, {})
			// 		.then(({
			// 			data
			// 		}) => {
			// 			this.loading_load = false;
			// 			if (200 == data.code && data.data) {
			// 				this.leaveMessage = data.data;
			// 			} else {
			// 				this.$message.warning(data.msg ? data.msg : this.$t('hytxs0000029'));
			// 			}
			// 		})
			// 		.catch(error => {
			// 			console.log(error);
			// 			this.$message.error('列表数据，请求失败！');
			// 			this.loading_load = false;
			// 		});
			// },
			submitAction() {
				if (!this.form.status) {
					this.$message.warning(this.$t('FormMsg.Select_long_status'))
					return;
				}
				let filterData = {
					"status": this.form.status, //"状态(10: 提交  30： 正常结束  40: 异常结束)",
					"qaMoreText": this.form.qaMoreText, //this.$t('i18nn_450e8e72d3a52ce2'),
					"qaText": this.form.qaText, //this.$t('i18nn_3d23fd1011cdc075'),
					"attachments":  this.form.attachments
				};

				this.loading_load = true;
				this.$http
					.put(this.$urlConfig.WhQARecProgressAdd + "/" + this.filterData.id, filterData)
					.then(({
						data
					}) => {
						this.loading_load = false;
						if (200 == data.code) {
							this.$message.success(this.$t('i18nn_b93f7c8c4e2346f8'));
							this.dialogFile = false;
							this.$emit("SaveSuccessBack");
						} else {
							this.$message.warning(data.msg ? data.msg : this.$t('i18nn_a7d2e953195a5588'));
						}
					})
					.catch(error => {
						console.log(error);
						this.$message.error("提交失败！");
						this.loading_load = false;
					});
			},
			//选择行
			// handleCurrentChange(row, event, column) {
			// 	// this.$refs.multipleTable.toggleRowSelection(row, true); //点击选中
			// },
			// //多选
			// handleSelectionChange(val) {
			// 	console.log(val);
			// 	// this.multipleSelection = val;
			// },


		}
	};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">

</style>
